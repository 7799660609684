import React, {useState} from 'react'


export default function Accordion({text, id}) {
  
    const [selected, setSelected] = useState(null);

    const toogle = i => {
        if(selected === id) {
            return setSelected(null);
        }

        setSelected(i);
     
    }

return (
        <div>
            <div className='clause-explained--added'>
                <p>Co to?</p>
                <span className='accordion--btn' onClick={() => toogle(id)}> {selected === id ? "-": "+"}</span>
            </div>
            <p className={selected === id ? "content-show" : "content-hide"}>{text}</p>    
        </div>
  )
}
