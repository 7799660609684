import logo from './logo.svg';
import './App.css';
import { useState } from 'react';
import axios from 'axios';
import FileDownload from "js-file-download"
import {DndProvider} from 'react-dnd';
import {HTML5Backend} from 'react-dnd-html5-backend';
import DragDrop from './components/DragDrop';

const cors = require('cors');



function App() {


  return (
   <DndProvider backend={HTML5Backend}>
      <div className='App'>
        <DragDrop />
      </div>
   </DndProvider>
    
  );
}

export default App;
