import React, { useState, useRef } from 'react';
import Accordion from './Accordion';
import { useDrag, useDrop } from 'react-dnd';
import axios from 'axios';

export default function Clause({ key, index, desc, data,  title, text, updateAgreement, removeFromAgreement, moveListItem, inputs, buttons}) {

    // useDrag - the list item is draggable
    const [{ isDragging }, dragRef] = useDrag({
        type: 'item',
        item: { index },
        collect: (monitor) => ({
            isDragging: monitor.isDragging(),
        }),
    });

    const apiCall = (path, input) => {
        const options = {
            method: 'GET',
            url: `http://localhost:8000/${path}`,
            params: {input: input}
          }
        
        axios.request(options).then(res => {
        console.log(res.data);

        // Zakładając, że odpowiedź z API zawiera nowe wartości name i company_name
        const newNameFromApi = `${res.data[0].wlasciciel.imie} ${res.data[0].wlasciciel.nazwisko}`;
        const newCompanyNameFromApi = res.data[0].nazwa;
        const newCompanyCity = res.data[0].adresDzialalnosci.miasto;
        const newStreet = res.data[0].adresDzialalnosci.ulica;
        const newNIPFromApi = res.data[0].wlasciciel.nip;
        const newREGONFromApi = res.data[0].wlasciciel.regon;

        // Zaktualizuj tablicę agreement z nowymi wartościami z API
        const updatedAgreement = data.map((item) => ({
          ...item,
          name: newNameFromApi,
          company_name: newCompanyNameFromApi,
          city: newCompanyCity,
          street: newStreet,
          nip: newNIPFromApi,
          regon: newREGONFromApi
        }));

        // Ustaw zaktualizowaną tablicę za pomocą updateAgreement (przekazaną z komponentu rodzica)
        updateAgreement(updatedAgreement);
      })
      .catch((error) => {
        console.error("Błąd podczas wywoływania API:", error);
      });
    }


    // useDrop - the list item is also a drop area
    const [spec, dropRef] = useDrop({
        accept: 'item',
        hover: (item, monitor) => {
            const dragIndex = item.index
            const hoverIndex = index
            const hoverBoundingRect = ref.current?.getBoundingClientRect()
            const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2
            const hoverActualY = monitor.getClientOffset().y - hoverBoundingRect.top

            // if dragging down, continue only when hover is smaller than middle Y
            if (dragIndex < hoverIndex && hoverActualY < hoverMiddleY) return
            // if dragging up, continue only when hover is bigger than middle Y
            if (dragIndex > hoverIndex && hoverActualY > hoverMiddleY) return

            moveListItem(dragIndex, hoverIndex)
            item.index = hoverIndex
        },
    })

     // Join the 2 refs together into one (both draggable and can be dropped on)
     const ref = useRef(null)
     const dragDropRef = dragRef(dropRef(ref))
 

    const handleClick = () => {
        // Wywołaj funkcję removeFromAgreement, aby usunąć klauzulę o danym "id" z umowy
    
        removeFromAgreement(index);
    };

      
    const [inputValues, setInputValues] = useState(new Array(inputs.values.length).fill(""));
    

    const handleInputChange = (index, value) => {
        // Aktualizacja stanu dla danej wartości inputu
        const updatedInputValues = [...inputValues];
        console.log(value);
        updatedInputValues[index] = value;
        setInputValues(updatedInputValues);
    };

    console.log(index);

    return (
        <div key={index} className='clause-added move' ref={dragDropRef} style={{ cursor: isDragging ? 'grabbing' : 'grab', border: isDragging ? "2px solid red" : ""}}>
            <span>{title}</span>
            <p>halo {index}</p>
            <svg className='btn--close' width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" onClick={handleClick}>
                <path d="M12 22C17.5 22 22 17.5 22 12C22 6.5 17.5 2 12 2C6.5 2 2 6.5 2 12C2 17.5 6.5 22 12 22Z" stroke="#292D32" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                <g opacity="0.4">
                <path d="M9.16992 14.8299L14.8299 9.16992" stroke="#292D32" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M14.8299 14.8299L9.16992 9.16992" stroke="#292D32" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                </g>
            </svg>
            <div className='flex'>
                <div>
                    {inputs.exists && inputs.values.map((value, index2) => (
                        <input
                        id={index2}
                        key={index2}
                        type="text"
                        placeholder={value}
                        value={inputValues[index2]} // Przypisanie wartości inputu z odpowiedniego stanu
                        onChange={(e) => handleInputChange(index2, e.target.value)} // Obsługa zmiany wartości inputu // Przyjmuję, że wartość "desc" będzie używana jako wartość pola tekstowego
                    />
                    ))}
                </div>
                <div>
                    {buttons.exists && buttons.values.map((value, index3) => (
                    <button  onClick={(e) => {
                        const inputIndex = e.target.getAttribute("data-input-index");
                        console.log(inputs.values[inputIndex]);
                        const inputValue = inputValues[inputIndex];
                        apiCall(value.path, inputValue);
                      }} data-input-index={index3}>{value.text}</button>
                    ))}
                </div>
            </div>
            <Accordion text={desc} id={key} />
        </div>
    );
}