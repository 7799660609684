import React from 'react';
import { useDrag } from 'react-dnd';

export default function Clause({ id, title, text, removeFromAgreement, area }) {
    const [{ isDragging }, drag] = useDrag(() => ({
        type: "div",
        item: { id: id },
        collect: (monitor) => ({
            isDragging: !!monitor.isDragging(),
        }),
    }));

    const handleClick = () => {
        // Wywołaj funkcję removeFromAgreement, aby usunąć klauzulę o danym "id" z umowy
        removeFromAgreement(id);
    };

    return (
        <div className='clause' ref={drag}>
            <span>{title}</span>
        </div>
    );
}
