import React from 'react'

export default function Navbar() {
  return (
    <div className='navbar'>
        <ul>
            <li>O nas</li>
        </ul>
    </div>
  )
}
