import React from 'react'
import Clause from './Clause';
import ClauseAdded from './ClauseAdded'
import { useState, useMemo, useCallback } from 'react';
import { useDrop, DndProvider, DndContext} from 'react-dnd';
import {closestCenter} from "@dnd-kit/core";
import {arrayMove, SortableContext, verticalListSortingStrategy} from "@dnd-kit/sortable";
import { HTML5Backend } from 'react-dnd-html5-backend'
import '../App.css';
import Navbar from './Navbar';
import { isFocusable } from '@testing-library/user-event/dist/utils';
import Tabs from './Tabs';




const clausesID = [
    {
        id: 1,
        title: "Klauzula salwatoryjna",
        text: "W razie nieważności któregokolwiek postanowienia niniejszej Umowy, pozostałe postanowienia pozostają w mocy",
        source: "clauses",
        desc: "Klauzula salwatoryjna to postanowienie lub zapis umowy, który ma na celu ochronę strony umowy przed skutkami nieważności (powstałej np. w wyniku zmiany przepisów prawa) lub niewykonalności innych postanowień umownych.",
        area: 11,
        inputs: {
            exists: false,
            values: []
        },
        btns: {
            exists: false,
            values: []
        },
    },
    {
        id: 2,
        title: "Klauzula zapisu na sąd polubowny",
        text: "Wszelkie spory wynikające z niniejszej umowy lub w związku z nią, w tym wszelkie pytania dotyczące jej istnienia, ważności lub rozwiązania, zostaną przekazane i ostatecznie rozstrzygnięte w drodze arbitrażu administrowanego przez Międzynarodowe Centrum Arbitrażu w Singapurze (SIAC) zgodnie z Zasadami Arbitrażu Międzynarodowego Centrum Arbitrażu w Singapurze (Zasady SIAC) w chwili obecnej, które to zasady uważa się za włączone przez odniesienie do niniejszej klauzuli.",
        source: "clauses",
        desc: "Klauzula zapisu na sąd polubowny to specjalne postanowienie w umowie, które zobowiązuje strony do rozstrzygania wszelkich sporów związanych z tą umową przez arbitraż, a nie przez tradycyjny sąd państwowy.",
        area: 11,
        inputs: {
            exists: false,
            values: []
        },
        btns: {
            exists: false,
            values: []
        },
    },
    {
        id: 3,
        title: "Klauzula kara umowna",
        text: "W przypadku gdy Zleceniobiorca naruszy zakaz konkurencji określony w pkt. 4, zapłaci on na rzecz zleceniodawcy karę umowną w wysokości 10.000,00 złotych za każde naruszenie.",
        source: "clauses",
        desc: "Klauzula kary umownej to zapis w umowie, który określa z góry konkretną sumę pieniężną lub inne środki, które strona umowy musiałaby zapłacić w przypadku niewykonania lub nienależytego wykonania swoich zobowiązań umownych.",
        area: 9,
        inputs: {
            exists: false,
            values: []
        },
        btns: {
            exists: false,
            values: []
        },
    },
    {
        id: 4,
        title: "Oznaczenie spółki handlowej",
        name: "..",
        form: "..",
        city: "..",
        street: "..",
        court: ".",
        krs: ".",
        nip: ".",
        regon: ".",
        capital: ".",
        represented: ".",
        text: `KRS`,
        source: "clauses",
        desc: "Klauzula kary umownej to zapis w umowie, który określa z góry konkretną sumę pieniężną lub inne środki, które strona umowy musiałaby zapłacić w przypadku niewykonania lub nienależytego wykonania swoich zobowiązań umownych.",
        area: 2,
        inputs: {
            exists: true,
            values: ["Wpisz numer KRS", "Osoba uprawniona do reprezentacji"]
        },
        btns: {
            exists: true,
            values: [{
                text: "Pobierz dane z KRS",
                type: "API",
                path: "ceidg",
                value: "7641686756"
            }, {
                text: "Dodaj osobę uprawnioną do reprezentacji",
                type: "inner",
                path: "addRep",
                value: ""
            }]
        },
    },
    {
        id: 5,
        title: "Oznaczenie jednoosobowej działalności gospodarczej",
        name: "AA",
        company_name: "..",
        city: "..",
        street: "BB",
        nip: "..",
        regon: "90908",
        text: `JDG`,
    //    ${this.name} zamieszkałą w ${this.city}, prowadzącym działalność gospodarczą pod firmą ${this.company_name}, pod adresem ${this.street} ${this.city}, NIP ${this.nip}, REGON ${this.regon}.
        source: "clauses",
        desc: "Klauzula kary umownej to zapis w umowie, który określa z góry konkretną sumę pieniężną lub inne środki, które strona umowy musiałaby zapłacić w przypadku niewykonania lub nienależytego wykonania swoich zobowiązań umownych.",
        area: 2,
        inputs: {
            exists: true,
            values: ["Wpisz numer NIP", "Osoba uprawniona do reprezentacji"]
        },
        btns: {
            exists: true,
            values: [{
                text: "Pobierz dane z CEIDG",
                type: "API",
                path: "ceidg",
                value: ""
            }, {
                text: "Dodaj osobę uprawnioną do reprezentacji",
                type: "inner",
                path: "addRep",
                value: ""
            }]
        },
    }
    
];

export default function DragDrop() {

    const updateAgreement = (updatedAgreement) => {
        setAgreement(updatedAgreement);
      };

    const [showSend, setShowSend] = useState(false);


    const [text, setText] = useState([]);

    const [toggleState, setToggleState] = useState(1);

    const toggleTab = (index) => {
        setToggleState(index);
    }


    const [agreement, setAgreement] = useState([]);
    const agreementWithMemo = useMemo(() => agreement, [agreement]);

    const removeFromAgreement = (id) => {
        setAgreement((prevAgreement) => {
            const updatedAgreement = [...prevAgreement];
            updatedAgreement.splice(id, 1);
            return updatedAgreement;
        });
    };

    const [{isOver}, drop] = useDrop(() => ({
        accept: "div",
        drop: (item) => addClauseToAgreement(item.id),
        collect: (monitor) => ({
            isOver: !!monitor.isOver(),
        }),
    }))

    const addClauseToAgreement = (id) => {
        const clauseToAdd = clausesID.find((clause) => id === clause.id);
       
        if (clauseToAdd && clauseToAdd.source !== "agreements") {
            // Tworzymy kopię klauzuli i zmieniamy wartość pola "source" na "agreements"
            const clauseWithSourceUpdated = { ...clauseToAdd, source: "agreements" };
            
            setAgreement((agreement) => [...agreement, clauseWithSourceUpdated]);
        }
    };



  const send = () => {
    
    const textArr = [];

   

    agreement.map((clause) => {
       
        if(clause.area === 2 && clause.text === "JDG"){
            const text = `${clause.name} zamieszkały/a w miejscowości ${clause.city}, prowadzącym/ą działalność gospodarczą pod firmą ${clause.company_name}, pod adresem ${clause.street} ${clause.city}, NIP: ${clause.nip}, REGON: ${clause.regon}.`
            textArr.push(text);
        }
        else if(clause.area === 2 && clause.text === "KRS"){
            const text = `${clause.name} ${clause.form} z siedzibą w ${clause.city}, ul. ${clause.street}, wpisana do Krajowego
            Rejestru Przedsiębiorców prowadzonego przez Sąd Rejonowy w ${clause.court} - Wydział
            Gospodarczy Krajowego Rejestru Sądowego, pod numerem KRS ${clause.krs}, NIP
            ${clause.nip}, REGON ${clause.regon}, kapitał zakładowy ${clause.capital},
            reprezentowana jednoosobowo przez ${clause.represented}.`;
            textArr.push(text);
        }
        else{
            textArr.push(clause.text);
        }
       
    });

   
    console.log(textArr);
    const text = textArr.join('\n');

    setText(text);
    
    console.log(`${text}`);
  }

  const handleDragEnd = (event) => {
  }

//   const movePetListItem = useCallback(
//     (dragIndex, hoverIndex) => {
//         const dragItem = agreement[dragIndex]
//         const hoverItem = agreement[hoverIndex]
//         // Swap places of dragItem and hoverItem in the pets array
//         setAgreement(agreement => {
//             const updatedAgreement = [...agreement]
//             updatedAgreement[dragIndex] = hoverItem
//             updatedAgreement[hoverIndex] = dragItem
//             return updatedAgreement
//         })
//     },
//     [agreement],
// )

const moveClauseItem = useCallback(
    (dragIndex, hoverIndex) => {
        const dragItem = agreement[dragIndex]
        const hoverItem = agreement[hoverIndex]

        setAgreement(agreement => {
            const updatedAgreement = [...agreement]
            updatedAgreement[dragIndex] = hoverItem
            updatedAgreement[hoverIndex] = dragItem
            return updatedAgreement
        })
    },
    [agreement],
)


  return (
    <div className='wrapper'>
        <Navbar />
        <div className='working-area'>
       
            {/* <div className='clauses'> */}
                {/* <Tabs /> */}

                <div className='clauses container flex'>
                    <div className='content-tabs'>
                        <div className={toggleState === 1 ? "content active-content" : "content"}>
                            <h2>Gotowe wzory</h2>
                            <div> 
                            {clausesID.map((clause) => {
                                if(clause.area === 1) {
                                    return <Clause id={clause.id} text={clause.text} title={clause.title}/>
                                }
                            })}
                            </div>
                        </div>
                        <div className={toggleState === 2 ? "content active-content" : "content"}>
                            <h2>Oznaczenie stron</h2>
                            <div> 
                            {clausesID.map((clause) => {
                                if(clause.area === 2) {
                                    return <Clause id={clause.id} text={clause.text} title={clause.title}/>
                                }
                            })}
                            </div>
                        </div>
                        <div className={toggleState === 3 ? "content active-content" : "content"}>
                            <h2>Preambuła</h2>
                            <div> 
                            {clausesID.map((clause) => {
                                if(clause.area === 3) {
                                    return <Clause id={clause.id} text={clause.text} title={clause.title}/>
                                }
                            })}
                            </div>
                        </div>
                        <div className={toggleState === 4 ? "content active-content" : "content"}>
                            <h2>Słownik pojęć</h2>
                            <div> 
                            {clausesID.map((clause) => {
                                if(clause.area === 4) {
                                    return <Clause id={clause.id} text={clause.text} title={clause.title}/>
                                }
                            })}
                            </div>
                        </div>
                        <div className={toggleState === 5 ? "content active-content" : "content"}>
                            <h2>Przedmiot umowy</h2>
                            <div> 
                            {clausesID.map((clause) => {
                                if(clause.area === 5) {
                                    return <Clause id={clause.id} text={clause.text} title={clause.title}/>
                                }
                            })}
                            </div>
                        </div>
                        <div className={toggleState === 6 ? "content active-content" : "content"}>
                            <h2>Miejsce wykonania umowy</h2>
                            <div> 
                            {clausesID.map((clause) => {
                                if(clause.area === 6) {
                                    return <Clause id={clause.id} text={clause.text} title={clause.title}/>
                                }
                            })}
                            </div>
                        </div>
                        <div className={toggleState === 7 ? "content active-content" : "content"}>
                            <h2>Obowiązki Stron</h2>
                            <div> 
                            {clausesID.map((clause) => {
                                if(clause.area === 7) {
                                    return <Clause id={clause.id} text={clause.text} title={clause.title}/>
                                }
                            })}
                            </div>
                        </div>
                        <div className={toggleState === 8 ? "content active-content" : "content"}>
                            <h2>Wynagrodzenie</h2>
                            <div> 
                            {clausesID.map((clause) => {
                                if(clause.area === 8) {
                                    return <Clause id={clause.id} text={clause.text} title={clause.title}/>
                                }
                            })}
                            </div>
                        </div>
                        <div className={toggleState === 9 ? "content active-content" : "content"}>
                            <h2>Kary umowne</h2>
                            <div> 
                            {clausesID.map((clause) => {
                                if(clause.area === 9) {
                                    return <Clause id={clause.id} text={clause.text} title={clause.title}/>
                                }
                            })}
                            </div>
                        </div>
                        <div className={toggleState === 10 ? "content active-content" : "content"}>
                            <h2>Odpowiedzialność</h2>
                            <div> 
                            {clausesID.map((clause) => {
                                if(clause.area === 10) {
                                    return <Clause id={clause.id} text={clause.text} title={clause.title}/>
                                }
                            })}
                            </div>
                        </div>
                        <div className={toggleState === 11 ? "content active-content" : "content"}>
                            <h2>Postanowienia końcowe</h2>
                            <div> 
                            {clausesID.map((clause) => {
                                if(clause.area === 11) {
                                    return <Clause id={clause.id} text={clause.text} title={clause.title}/>
                                }
                            })}
                            </div>
                        </div>
                        <div className={toggleState === 12 ? "content active-content" : "content"}>
                            <h2>Podpisy</h2>
                            <div> 
                            {clausesID.map((clause) => {
                                if(clause.area === 12) {
                                    return <Clause id={clause.id} text={clause.text} title={clause.title}/>
                                }
                            })}
                            </div>
                        </div>
                        <div className={toggleState === 13 ? "content active-content" : "content"}>
                            <h2>Załączniki</h2>
                            <div> 
                            {clausesID.map((clause) => {
                                if(clause.area === 13) {
                                    return <Clause id={clause.id} text={clause.text} title={clause.title}/>
                                }
                            })}
                            </div>
                        </div>
                    </div>
                    <div className='bloc-tabs'>
                        <div className={toggleState === 1 ? "tabs active-tabs" : "tabs"} onClick={() => toggleTab(1)}>Gotowe wzory</div>
                        <div className={toggleState === 2 ? "tabs active-tabs" : "tabs"} onClick={() => toggleTab(2)}>Oznaczenie stron</div>
                        <div className={toggleState === 3 ? "tabs active-tabs" : "tabs"} onClick={() => toggleTab(3)}>Preambuła</div>
                        <div className={toggleState === 4 ? "tabs active-tabs" : "tabs"} onClick={() => toggleTab(4)}>Słownik pojęć</div>
                        <div className={toggleState === 5 ? "tabs active-tabs" : "tabs"} onClick={() => toggleTab(5)}>Przedmiot umowy</div>
                        <div className={toggleState === 6 ? "tabs active-tabs" : "tabs"} onClick={() => toggleTab(6)}>Miejsce wykonania umowy</div>
                        <div className={toggleState === 7 ? "tabs active-tabs" : "tabs"} onClick={() => toggleTab(7)}>Obowiązki stron</div>
                        <div className={toggleState === 8 ? "tabs active-tabs" : "tabs"} onClick={() => toggleTab(8)}>Wynagrodzenie</div>
                        <div className={toggleState === 9 ? "tabs active-tabs" : "tabs"} onClick={() => toggleTab(9)}>Kary umowne</div>
                        <div className={toggleState === 10 ? "tabs active-tabs" : "tabs"} onClick={() => toggleTab(10)}>Odpowiedzialność</div>
                        <div className={toggleState === 11 ? "tabs active-tabs" : "tabs"} onClick={() => toggleTab(11)}>Postanowienia końcowe</div>
                        <div className={toggleState === 12 ? "tabs active-tabs" : "tabs"} onClick={() => toggleTab(12)}>Podpisy</div>
                        <div className={toggleState === 13 ? "tabs active-tabs" : "tabs"} onClick={() => toggleTab(13)}>Załączniki</div>
                    </div>
                </div>
            {/* </div> */}
            <div ref={drop} className='agreement'>
                <input className='city-time' type="text" placeholder='Miejscowość, dzień, miesiąc, rok'/>
                <input className='agreement-title' type="text" placeholder='Nazwa umowy'/>
                    {agreement.map((clause, index) => {
                        return <ClauseAdded className='move' key={clause.id} index={index} text={clause.text} title={clause.title} desc={clause.desc} removeFromAgreement={removeFromAgreement} data={agreement} updateAgreement={updateAgreement} setData={setAgreement}  moveListItem={moveClauseItem} inputs={clause.inputs} buttons={clause.btns}/>
                    })}
            </div>
            <div className='preview'>
                {/* to do agreement for each p */}
                <div>
                 <p>{text}</p> 
                </div>

                <button onClick={send}>Generuj umowę!</button>
            </div>
        </div>
    </div>
  )
}